<template>
  <v-dialog
      v-model="dialog"
      max-width="600"
  >
  <!-- Activator -->
  <template #activator="{ on, attrs }">
    <v-btn
        v-bind="attrs"
        color="primary"
        v-on="on"
        @click="evaluate()"
    >
      {{ price ? `$${price}` : 'evaluate' }}
    </v-btn>
  </template>

  <!-- Pop-up Content -->
  <v-card>
    <v-toolbar color="primary" dark>
      <v-toolbar-title>Evaluation Results</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon @click="dialog = false">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>

    <v-card-text>
      <div v-for="(item, index) in evaluation" :key="index">
        <strong>{{ index }}</strong>: {{ item }}
      </div>
    </v-card-text>

    <v-card-actions>
      <v-btn color="primary" text @click="dialog = false">Close</v-btn>
    </v-card-actions>
  </v-card>
  </v-dialog>
</template>

<script>
import api from "@/api/backend-api";

export default {
  props: ["domainName", "suggestedPrice"],
  data: () => ({
    loading: false,
    price: '',
    evaluation: {},
    dialog: false, // Controls the dialog (pop-up) visibility
  }),
  computed: {

  },
  mounted() {
    this.price = this.suggestedPrice;
  },
  methods: {
    async evaluate() {
      this.loading = true;
      const { data } = await api.evaluate(this.domainName);
      this.price = data.price;
      this.evaluation = data;
      this.loading = false;

      // Open the dialog after obtaining the evaluation
      this.dialog = true;
    },
  },
};
</script>

<style></style>
