<template>
  <div class="autocomplete-result"  @click="handleClick">
    <span class="result-text">{{ template.text }}</span>
    <span class="result-times-used">{{ template.timesUsed }}</span>
  </div>
</template>

<script>
export default {
  name: "ResultItem",
  props: {
    template: {
      type: Object,
      required: true,
    },
  },
  methods: {
    handleClick() {
      this.$emit("select", this.template);
    },
  },
};
</script>

<style scoped>
.autocomplete-result {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  border: 1px solid #ddd;
  margin-bottom: 5px;
  cursor: pointer;
}

.autocomplete-result.is-active {
  background-color: #eef;
}

.result-text {
  font-weight: bold;
}

.result-times-used {
  color: #666;
}
</style>
