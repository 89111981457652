<template>
  <v-container :class="getClass" fluid @dblclick="$emit('collapse')">

    <v-row no-gutters justify="space-between" class="fill-width">
      <v-col>

        <v-tooltip bottom offset-overflow>
          <template #activator="{ on }">
            <v-avatar :color="getColor"
                      @click="copyName"
                      v-on="on" size="20" class="white--text pa-0">
              {{ getInitials }}
            </v-avatar>
          </template>
          <span>{{ this.getName }}</span>
        </v-tooltip>
      </v-col>

      <v-col>
        <date-display
            :date="message.dateCreated ? message.dateCreated : ''"
            date-format="YYYY MMM DD HH:mm:ss"
        />
      </v-col>
      <v-col>
        <SMSStatus :status="message.status" :error="message.errorCode"/>
      </v-col>
      <v-col>
        <v-select
            v-if="!message.user"
            v-model="messageClass"
            class="mx-2 pa-0"
            :items="messageClassification"
            hide-details
            label="Classify"
            single-line
        />
      </v-col>


    </v-row>
    <v-row>
      <v-col cols="12">
        <v-card v-if="message.type === 'RECORDING'">
          <v-card-title>VOICE MAIL</v-card-title>
          <v-card-text>
            {{ message.text }}
          </v-card-text>
          <v-card-actions>
            <v-btn @click="handleClick(message.src)"
            >Listen
            </v-btn>
          </v-card-actions>
        </v-card>
        <v-card v-else-if="message.type === 'MEDIA'">
          <v-card-text>{{ message.text }}</v-card-text>
          <v-card-actions>
            <v-btn @click="handleClick(message.src)"
            >Peek
            </v-btn>
          </v-card-actions>
        </v-card>

        <div v-if="message.html">

          <div v-html="sanitizedHtml"></div>

        </div>
        <div v-else>
          <pre>{{ message.text }}</pre>
        </div>


      </v-col>


    </v-row>
  </v-container>
</template>

<script>
import DOMPurify from "dompurify";
import {mapState} from "vuex";
import tools from "@/util/tools";

export default {
  name: "MessageItem",
  components: {
    DateDisplay: () => import("@/components/DateDisplay"),
    SMSStatus: () => import("@/components/message/SMSStatus"),
  },
  props: ["message", "contactName", "user"],
  computed: {
    ...mapState("messages", ["messageClassification"]),
    sanitizedHtml() {
      // Configure DOMPurify to remove `src` attributes from `<img>` tags
      // DOMPurify.addHook("uponSanitizeElement", (node, data) => {
      //   if (node.tagName === "IMG") {
      //     node.removeAttribute("src"); // Remove src attribute
      //   }
      // });

      // Safely sanitize the HTML
      return DOMPurify.sanitize(this.message.html
          ,
          {
            ALLOWED_TAGS: ["b", "i", "u", "p"], // Example: Allow only basic formatting
            ALLOWED_ATTR: ["href", "target"],// Example: Allow only specific attributes
          }
      );
      // return "";
    },

    getColor() {
      return this.message.user ? "green" : "red";
    },
    getClass() {
      return this.simple ? "" : this.message.user ? "user" : "client";
    },

    getInitials() {
      let name = "";
      if (this.message.contactName) {
        name = this.message.contactName;
      } else {
        name = this.contactName ? this.contactName : "No Name";
      }

      if (name) {
        return name
            .split(" ")
            .map((n, i, a) => (i === 0 || i + 1 === a.length ? n[0] : null))
            .join("");
      } else {
        return "L";
      }
    },
    getName() {
      return this.message.contactName ? this.message.contactName : "No Name";
    },
    messageClass: {
      get() {
        return this.message.classification;
      },
      set(value) {
        if (value === undefined) return;
        console.log("Event", value, "class", value);
        let payload = {
          id: this.message.id,
          classification: value,
        };
        console.log("Payload For UPDATE", payload);

        this.$store.dispatch("messages/updateMessageClassificationAction", payload);
      },
    },
  },
  methods: {
    updateClass(event) {
    },
    handleClick(src) {
      window.open(src, "_blank", "noreferrer");
    },
    async copyName() {
      await tools.copyTextToClipboard(this.getName);

    },
  },
};
</script>

<style lang="scss" scoped>
pre {
  white-space: pre-wrap; /* Enable wrapping */
  word-wrap: break-word; /* Legacy support for breaking words */
  overflow-wrap: break-word; /* Modern word breaking */
  font-family: monospace; /* Ensures consistent font */
}

.user {
  border: 2px solid rgb(12, 108, 163);
  border-radius: 15px;

}

.client {
  border: 2px solid rgb(208, 169, 169);
  border-radius: 15px;
}

.simpleText {
  font-weight: bold;
}

.regularText {
  font-weight: normal;
}

.fill-width {
  flex-wrap: nowrap;
}
</style>
