<template>
  <v-btn
      :color="valid ? 'primary' : 'error'"
      @click="validateEmail(lead.email)"
  >
    {{ lead.email }}
  </v-btn>
</template>

<script>
import backendApi from "@/api/backend-api";

export default {

  props: ["lead"],
  data: () => ({
    menu: false,
    inputText: '',
    valid: false,
  }),


  methods: {
    async validateEmail() {

      let response = await backendApi.validateEmail(this.lead.email);
      this.valid = response.data;
      const snack = {
        data: response.data,
        color: response.data ? "green" : "red",
        timeout: 1000,
        whois: false,
      };
      await this.$store.dispatch("snackbar/setSnackbar", snack);


      await this.$store.dispatch(
          "conversations/loadConversationsByEmailAction", this.lead
      )


    },

  }
}
</script>

<style></style>
